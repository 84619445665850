import React from 'react';
import Dialog from 'rc-dialog';
import classNames from 'utils/class-names';
import './modal.scss';
import { CloseIcon } from '../icons/close-icon';

type ModalProps = {
  width: number;
  centered?: boolean;
  visible?: boolean;
  focusTriggerAfterClose?: boolean;
  onCancel?: Function;
  children: React.ReactNode;
  className?: string;
  showMask?: boolean;
  closable?: boolean;
  maskClosable?: boolean;
};

let mousePosition: { x: number; y: number } | null;

const getClickPosition = (e: MouseEvent) => {
  mousePosition = {
    x: e.pageX,
    y: e.pageY
  };
  setTimeout(() => {
    mousePosition = null;
  }, 100);
};

if (typeof window !== 'undefined') {
  document.documentElement.addEventListener('click', getClickPosition, true);
}

export function Modal({
  visible,
  focusTriggerAfterClose,
  onCancel,
  centered,
  className,
  showMask,
  closable = true,
  maskClosable = true,
  ...restProps
}: ModalProps) {
  const handleCancel = (e: any) => {
    onCancel?.(e);
  };

  const wrapClassNameExtended = classNames({
    [`app-modal-centered`]: !!centered
  });

  return (
    <Dialog
      {...restProps}
      closable={closable}
      maskClosable={maskClosable}
      mask={showMask}
      className={className}
      prefixCls='app-modal'
      wrapClassName={wrapClassNameExtended}
      footer={null}
      visible={visible}
      mousePosition={mousePosition}
      onClose={handleCancel}
      closeIcon={<CloseIcon className='app-modal__close-icon' />}
      focusTriggerAfterClose={focusTriggerAfterClose}
      transitionName='app-zoom'
      maskTransitionName='app-fade'
      destroyOnClose={true}
    />
  );
}
