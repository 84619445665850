import React from 'react';
import { useLocation } from 'hooks/useLocation';

import { PinIcon, CartIcon, BinIcon } from 'layout/components/icons';
import { ProfileIcon } from 'layout/components/icons/profile-icon';
import { ArrowRight } from 'layout/components/icons/arrow-right';
import useTranslation from 'hooks/useTranslations';

import { AppSelect } from 'layout/components/app-select';
import { useShowDeleteProfileModal } from './useShowDeleteProfileModal';
import { useLanguage } from 'hooks';
import { usePush } from 'hooks/usePush';
import { useSelectBestHighestAvailableSubscription } from '../../../../redux/pricing-info/selectors';

import './ProfileTabDropdown.scss';

export function ProfileTabDropdown() {
  const push = usePush();
  const { pathname } = useLocation();
  const showDeleteProfileModal = useShowDeleteProfileModal();
  const translate = useTranslation();
  const language = useLanguage();

  const options = [
    {
      value: '/render/profile',
      title: (
        <div className='profile-tabs-dropdown__option'>
          <ProfileIcon />
          {translate('Account')}
        </div>
      )
    },
    {
      value: '/render/profile/subscription',
      title: (
        <div className='profile-tabs-dropdown__option'>
          <CartIcon />
          {translate('Subscription')}
        </div>
      )
    },
    {
      value: '/render/profile/connect-channel',
      title: (
        <div className='profile-tabs-dropdown__option'>
          <PinIcon />
          {translate('Linked Channels')}
        </div>
      )
    },
    {
      value: 'delete',
      title: (
        <div className='profile-tabs-dropdown__option'>
          <BinIcon />
          {translate('Delete')}
        </div>
      )
    }
  ];

  const subscription = useSelectBestHighestAvailableSubscription();
  const selectOptions = subscription
    ? options
    : options.filter((option) => option.value !== '/render/profile/subscription');

  return (
    <AppSelect
      value={pathname}
      isNewSelector
      customArrow={<ArrowRight />}
      className='profile-tabs-dropdown'
      style={language !== 'en' ? { minWidth: 215 } : {}}
      options={selectOptions}
      onChange={(value) => {
        if (value === 'delete') {
          showDeleteProfileModal();
        } else if (pathname !== value) {
          push(value);
        }
      }}
    />
  );
}
