import React, { useState } from 'react';
import { useGetChargesQuery } from '../../../../redux/api/payments';
import {
  useSelectBestHighestAvailableSubscription,
  useSelectPricingInfo
} from '../../../../redux/pricing-info/selectors';
import { unixToMmDdYyyy } from 'utils/unixTimestampTransform';
import StarPremium from 'assets/icons/white-star.svg';
import { ArrowRight } from 'layout/components/icons/arrow-right';
import Preloader from 'layout/components/preloader/Preloader';

import { LinkButton } from 'layout/components/link-button';
import useTranslation from 'hooks/useTranslations';
import './Payments.scss';

export const Payments = () => {
  const [count, setCount] = useState(5);
  const { data, isLoading } = useGetChargesQuery();
  const { isLoadedForLoggedInUser } = useSelectPricingInfo();
  const subscription = useSelectBestHighestAvailableSubscription();
  const translate = useTranslation();

  if (isLoading || !isLoadedForLoggedInUser) {
    return (
      <div>
        <Preloader width={100} height={100} className='payments__preloader' />
      </div>
    );
  }

  if (!data?.length) {
    return <div className='no-payments'>{translate("You don't have payment history yet")}</div>;
  }

  return (
    <div className='payments'>
      {data.slice(0, count).map((item) => {
        const { id, receipt_url, created, status, amount, currency } = item;
        return (
          <a
            className='payments__item'
            key={id}
            href={receipt_url}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='payments__details'>
              <div className='payments__date'>{unixToMmDdYyyy(created)}</div>
              <div className='payments__info'>
                <div className='payments__icon'>
                  <img src={StarPremium} alt='star' />
                </div>
                <div className='payments__title'>{subscription?.name}</div>
                <div className={`payments__status payments__status--${status}`}>{status}</div>
                <div className='payments__price'>
                  ${amount / 100} <span className='payments__currency'>{currency}</span>
                </div>
              </div>
            </div>
            <div className='payments__arrow'>
              <ArrowRight className='payments__arrow-icon' />
            </div>
          </a>
        );
      })}
      {data?.length > count && (
        <div>
          <LinkButton className='load-more-btn' onClick={() => setCount(count + 5)}>
            {translate('Load more')}
          </LinkButton>
        </div>
      )}
    </div>
  );
};

export default Payments;
