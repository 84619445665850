import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';
import useTranslation from 'hooks/useTranslations';

import './ProfileLimits.scss';

export function ProfileLimits() {
  const loggedIn: any = useSelector((state: IAppState) => state.loggedIn);
  const currentlyGeneratedAmount = loggedIn.limits['montly'].generated_count;
  const tracksLimitCount = loggedIn.limits['montly'].limit_count;

  const getenratedSecods = loggedIn.limits['daily'].generated_seconds;
  const limitSeconds = loggedIn.limits['daily'].limit_seconds;
  const translate = useTranslation();

  return (
    <div className='profile-limits'>
      <div className='profile-limits__container'>
        <div className='profile-limits__container_header'>
          <div>{translate("Seconds")}/{translate("day")}</div>
          <div>
            <b>{getenratedSecods}</b>/<span>{limitSeconds}</span>
          </div>
        </div>
        <div className='profile-limits__progress'>
          <div
            className='profile-limits__progress_seconds'
            style={{ width: `${(getenratedSecods / limitSeconds) * 100}%` }}
          />
        </div>
      </div>
      {!!tracksLimitCount && (
        <div className='profile-limits__container'>
          <div className='profile-limits__container_header'>
            <div>{translate("Tracks")}</div>
            <div>
              <b>{currentlyGeneratedAmount}</b>/<span>{tracksLimitCount}</span>
            </div>
          </div>
          <div className='profile-limits__progress'>
            <div
              className='profile-limits__progress_bar'
              style={{ width: `${(currentlyGeneratedAmount / tracksLimitCount) * 100}%` }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
