import React from 'react';
import {
  ISinglePurchasePricing,
  ISubscriptionPricing,
} from 'interfaces/pricing-info';
import { isSinglePurchase, isSubscriptionPricing } from 'utils/price-helpers';
import classNames from 'utils/class-names';
import './CardPrice.scss';
import useTranslation from 'hooks/useTranslations';

type CardPriceProps = {
  pricing: ISubscriptionPricing | ISinglePurchasePricing;
  showActivePeriod?: boolean;
  isBlackFriday?: boolean;
};

export function CardPrice({ pricing, showActivePeriod = true, isBlackFriday }: CardPriceProps) {
  let content: React.ReactNode = '';
  const translate = useTranslation();
  const className = classNames({
    'card-price': true,
    'card-price__free': pricing.isFree,
    'card-price__BF': isBlackFriday
  });
  const pricingPeriod = isSubscriptionPricing(pricing) ? pricing.period : undefined;

  if (pricing.isFree) {
    content = translate('free');
  } else if (isSinglePurchase(pricing)) {
    const { price } = pricing;
    const dollarSignClassNames = classNames({
      'card-price__dollar': String(price).startsWith('1')
    });

    content = (
      <>
        {pricing.priceTitle ? (
          pricing.priceTitle
        ) : (
          <>
            <span className={dollarSignClassNames}>$</span>
            {price / 100}
          </>
        )}
      </>
    );
  } else if (isBlackFriday) {
    if (pricing.isActive && showActivePeriod) {
      content = pricingPeriod === 'monthly' ? translate('Monthly') : translate('Annual');
    } else {
      const priceOption = pricing.priceTitle;
      const dollarSignClassNames = classNames({
        'card-price__dollar': priceOption?.startsWith('1')
      });

      const originalPrice = parseFloat(priceOption.replace(',', '.'));
      const discountedPrice = (originalPrice * 0.5).toFixed(2).toString();

      content = (
        <div className='black-friday-pricing'>
          <div className='black-friday-pricing__current'>
            <span className={dollarSignClassNames}>$</span>
            {discountedPrice}
          </div>
          <div className='black-friday-pricing__original'>
            <div className='black-friday-pricing__deals'>
              <div className='black-friday-pricing__original-price'>${originalPrice}</div>
              <div className='black-friday-pricing__discount'>-50%</div>
            </div>
            <div>
              <span className='black-friday-pricing__billing-cycle'>{translate('/mo')}</span>
            </div>
          </div>
        </div>
      );
    }
  } else if (pricingPeriod) {
    const priceOption = pricing.priceTitle;
    const dollarSignClassNames = classNames({
      'card-price__dollar': priceOption?.startsWith('1')
    });
    const [decimal, fractional] = priceOption.split(',');
    const decimalPartClassName = classNames({
      'card-price__whole': true,
      'card-price__whole_eleven': decimal === '11'
    });

    content = (
      <>
        <span className={dollarSignClassNames}>$</span>
        {priceOption.includes(',') ? (
          <>
            <span className={decimalPartClassName}>{decimal}</span>.
            <span className='card-price__fractional'>{fractional}</span>
          </>
        ) : (
          <span className={decimalPartClassName}>{priceOption}</span>
        )}
        <span className='card-price__symbol'>{translate('/mo')}</span>
      </>
    );
    // }
  }

  return <div className={className}>{content}</div>;
}
