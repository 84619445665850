import React from 'react';
import { useDispatch } from 'react-redux';

import { ModalNames } from 'constants/modal-names';
import { BinIcon } from 'layout/components/icons/bin-icon';

import { showModal } from '../../../../redux/modals/actions';
import { useDeleteUserProfileMutation } from '../../../../redux/api/profile-page';
import useTranslation from 'hooks/useTranslations';
import { useLogout } from 'hooks/useLogout';
import { usePush } from 'hooks/usePush';

export function useShowDeleteProfileModal() {
  const dispatch = useDispatch();
  const [deleteUserProfile] = useDeleteUserProfileMutation();
  const logout = useLogout();
  const translate = useTranslation();
  const push = usePush();

  return () =>
    dispatch(
      showModal({
        name: ModalNames.ConfirmModal,
        onComplete: () => {
          return deleteUserProfile()
            .then(() => logout())
            .then(() => {
              push('/render');
            })
        },
        small: true,
        extraSmall: true,
        additionalProps: {
          title: translate('Delete account'),
          icon: BinIcon,
          content: (
            <div className='confirm-delete'>
              <div className='confirm-delete__subtitle'>
                {translate('Are you sure you want to delete your account?')}
              </div>
              <div className='confirm-delete__text'>
                {translate('All tracks generated by you will be deleted along with your profile')}
              </div>
            </div>
          ),
          confirmButtonText: translate('Delete account')
        }
      })
    );
}
