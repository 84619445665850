import React from 'react';
import { Helmet } from 'react-helmet';
import { Artists } from './Artists';
import useTranslation from 'hooks/useTranslations';

export function ArtistsPage() {
  const translate = useTranslation();
  const helmet = (
    <Helmet>
      <title>{translate('meta.artists.title')}</title>
      <meta name='description' content={translate('meta.artists.description')} />
    </Helmet>
  );

  return (
    <>
      {helmet}
      <Artists />
    </>
  );
}
