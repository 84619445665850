import React from 'react';
import { format } from 'date-fns';
import className from 'utils/class-names';
import {
  useSelectBestHighestAvailableSubscription,
  useSelectPricingInfo
} from '../../../../redux/pricing-info/selectors';
import { ProfileLimits } from '../ProfileAccount/ProfileLimits';
import useTranslation from 'hooks/useTranslations';
import './CurrentSubscription.scss';

export function CurrentSubscription() {
  const subscription = useSelectBestHighestAvailableSubscription();
  const { isLoadedForLoggedInUser } = useSelectPricingInfo();
  const isPremium = !!subscription?.price;
  const translate = useTranslation();

  if (!isLoadedForLoggedInUser) {
    return null;
  }

  const titleClassName = className({
    'current-subscription__title': true,
    'current-subscription__title--free': !isPremium
  });

  const activeClassName = className({
    'current-subscription__active': true,
    'current-subscription__active--free': !isPremium
  });

  return (
    <div className='current-subscription'>
      <div className={titleClassName}>
        {subscription?.name}
        <div className={activeClassName}>
          {isPremium
            ? `${translate('plan active until')} ${format(subscription.expDate * 1000, 'MM/dd/yy')}`
            : `${translate('free plan')}`}
        </div>
      </div>
      <ProfileLimits />
    </div>
  );
}
