import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio } from 'layout/components/radio';
import classNames from 'utils/class-names';

import './PurchaseOption.scss';

type PurchaseOptionProps = {
  id: string;
  name: string;
  description: string;
  price: string;
  isActive?: boolean;
  isFree?: boolean;
  expDate?: number;
  isCanceled?: boolean;
  currentTier?: boolean;
  isBlackFriday?: boolean;
  className?: string;
};

export const PurchaseOption = ({
  id,
  name,
  description,
  price,
  isActive,
  className,
  isFree,
  isBlackFriday
}: PurchaseOptionProps) => {
  const { control, watch } = useFormContext();
  const selectedLicenseId = watch('licence');
  const wrapperClassName = classNames({
    'purchase-option': true,
    'purchase-option_active': isActive,
    'purchase-option_checked': selectedLicenseId === id,
    [className]: !!className
  });

  const extractPriceNumber = (priceStr: string) => {
    const numericValue = priceStr.replace(/[^0-9,.\s]/g, '').trim();
    const formattedValue = numericValue.replace(',', '.');
    return Number(formattedValue);
  };
  const cleanPrice = extractPriceNumber(price);

  const hasDiscount = isBlackFriday && !isFree;
  const discountPrice = hasDiscount ? (cleanPrice / 2).toFixed(2) : null;

  const formattedDiscountPrice = discountPrice
    ? parseFloat(discountPrice).toLocaleString('de-DE')
    : null;

  const purchaseOptionContentClassName = classNames({
    'purchase-option__content': true,
    'purchase-option__content_BF': isBlackFriday
  });

  return (
    <Controller
      control={control}
      name='licence'
      render={({ field }) => (
        <div className={wrapperClassName}>
          <Radio {...field} checked={field.value === id} value={id} isActive={isActive}>
            <div className={purchaseOptionContentClassName}>
              <div className='purchase-option__name'>
                {name}
                {isBlackFriday && !isFree && <div className='purchase-option__discount'>-50%</div>}
              </div>
              <div className='purchase-option__description'>{description}</div>
            </div>
            {!isBlackFriday || isFree ? (
              <div className='purchase-option__price'>{price}</div>
            ) : (
              <div className='purchase-option__black-friday'>
                <div className='purchase-option__black-friday--old'>{price}</div>
                <div className='purchase-option__black-friday--new'>
                  ${formattedDiscountPrice}/mo
                </div>
              </div>
            )}
          </Radio>
        </div>
      )}
    />
  );
};
