import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';

import { ProfileCover } from './ProfileCover';
import { ProfileAuth } from './ProfileAuth';
import { ProfileDetails } from './ProfileDetails';

export function ProfileAccount() {
  const loggedIn = useSelector((state: IAppState) => state.loggedIn);

  return (
    <>
      <div className='profile-content__info'>
        <ProfileCover />
        <ProfileAuth
          email={loggedIn['email']}
          googleId={loggedIn['social_google_id']}
          facebookId={loggedIn['social_facebook_id']}
          auth={loggedIn['auth_methods']}
        />
      </div>
      <div className='profile-content__limits'>
        <ProfileDetails emailNotification={loggedIn?.['email_notification']} />
      </div>
    </>
  );
}
