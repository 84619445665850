import { useEffect, useState } from 'react';

import axios from 'axios';

export function useFetch<T>(
  url: string,
  initialState: T = null,
  callback?: (T) => any,
  deps: any[] = []
): [T, (data: T) => void, () => void, boolean] {
  const [data, setData] = useState<T>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [random, setRandom] = useState(Math.random());
  const reload = () => setRandom(Math.random());
  async function getData() {
    const result = await axios.get(url);
    if (result.data.data) {
      return result.data.data;
    }
    return result.data;
  }

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    getData()
      .then((data) => {
        setIsLoading(false);
        if (callback) {
          callback(data);
        }

        if (isMounted) {
          setData(data);
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
      });

    return () => isMounted = false;
  }, [url, random, ...deps]);

  return [data, setData, reload, isLoading];
}
