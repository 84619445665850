import React, { useState } from 'react';
import { Field } from 'layout/components/field';
import { Input } from 'layout/components/input';
import { Button } from 'layout/components/app-button';
import { GoogleIcon } from 'layout/components/icons/google-icon';
import { FacebookIcon } from 'layout/components/icons/facebook-icon';
import { AuthWithSocials } from './AuthWithSocials';
import { useDispatch } from 'react-redux';
import { authFacebook, authGoogle } from 'utils/naviagate-home';
import axios from 'axios';
import { showModal, hideModal } from '../../../../redux/modals/actions';
import { addNotification } from '../../../../redux/notifications/actions';
import { ModalNames } from 'constants/modal-names';
import { NO_TURNSTILE_TOKEN } from 'constants/common';
import useTranslation from 'hooks/useTranslations';

import './ProfileAuth.scss';

type ProfileAuthProps = {
  email: string;
  googleId: string | null;
  facebookId: string | null;
  auth: {
    email: boolean;
    google: boolean;
    facebook: boolean;
  };
};

export function ProfileAuth({ email, googleId, facebookId, auth }: ProfileAuthProps) {
  // const [mode, setMode] = useState(auth.email ? 'emailExists' : 'linkAccount');
  const [mode, setMode] = useState('emailExists');
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const translate = useTranslation();

  function handleGoogleConfirm() {
    authGoogle({ redirectUrl: window.location.href, turnstileToken: NO_TURNSTILE_TOKEN });
  }

  function handleFacebookConfirm() {
    authFacebook({ redirectUrl: window.location.href, turnstileToken: NO_TURNSTILE_TOKEN });
  }

  const handleChangeMode = () => {
    if (auth.email) {
      setMode('changePassword');
    } else {
      setMode('setPassword');
    }
  };

  const handleConfirm = async ({ email, password }) => {
    if (!password) {
      setError(translate('The field is required'));
      return;
    }

    if (password.length < 8 || password.length > 30) {
      setError(translate('The password should be 8-30 characters long'));
      return;
    }

    await axios.post('/v1/UserSendRecoveryCode', {
      method: 'UserSendRecoveryCode',
      params: { email, password }
    });

    dispatch(
      showModal({
        name: ModalNames.VerificationCode,
        extraSmall: true,
        onComplete: async (code) => {
          await axios
            .post('/v1/UserSetPassword', {
              method: 'UserSetPassword',
              params: { email, password, code }
            })
            .then((response) => {
              if (response.data.error || response.data.data.code === 2) {
                throw new Error(response.data.data.text);
              } else {
                dispatch(
                  addNotification({
                    title: translate('Password successfully changed')
                  })
                );
                setMode('emailExists');
              }
            });
          dispatch(hideModal());
        }
      })
    );
  };

  const handleCancel = (newMode: string) => {
    setPassword('');
    setError('');
    setMode('emailExists');
  };

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
    };

  return (
    <div className='profile-auth'>
      {/* <Field label={auth.email ? 'Email' : 'Link an Email'} error={error}> */}
      <Field label='Email' error={error}>
        {/* {mode === 'linkAccount' && (
          <>
            <Input
              type='email'
              value={linkEmail}
              placeholder='Enter email'
              onChange={(value) => {
                handleInputChange(setLinkEmail)(value);
                setError('');
              }}
              error={!!error}
              className='profile-auth__input'
            />
            <Button onClick={handleNext} className='profile-auth__button' disabled={!linkEmail}>
              Next
            </Button>
          </>
        )} */}
        {mode === 'emailExists' && (
          <>
            <Input type='email' value={email} className='profile-auth__input' readonly />
            <Button onClick={handleChangeMode} className='profile-auth__button'>
              {auth.email ? `${translate('Change password')}` : `${translate('Set a password')}`}
            </Button>
          </>
        )}
        {(mode === 'setPassword' || mode === 'changePassword') && (
          <>
            <Input
              type='password'
              value={password}
              placeholder={translate('Set a password')}
              onChange={(value) => {
                handleInputChange(setPassword)(value);
                setError('');
              }}
              error={!!error}
              className='profile-auth__input'
            />
            <div className='profile-auth__buttons'>
              <Button
                onClick={() => handleConfirm({ email, password })}
                className='profile-auth__button'
                disabled={!password}
              >
                {mode === 'changePassword' ? `${translate('Save new password')}` : `${translate('Save a password')}`}
              </Button>
              <Button
                onClick={() => handleCancel(mode === 'setPassword' ? 'linkAccount' : 'emailExists')}
                className='profile-auth__button profile-auth__button_cancel'
              >
                {translate("Cancel")}
              </Button>
            </div>
          </>
        )}
      </Field>
      <div className='profile-auth__links'>
        <div className='profile-auth__title'>{translate("Link an account")}</div>
        <div className='profile-auth__socials'>
          <AuthWithSocials
            icon={<GoogleIcon />}
            id={googleId}
            email={email}
            platform='google'
            onClick={handleGoogleConfirm}
          />
          <AuthWithSocials
            icon={<FacebookIcon />}
            id={facebookId}
            email={email}
            platform='facebook'
            onClick={handleFacebookConfirm}
          />
        </div>
      </div>
    </div>
  );
}
