import React from 'react';
import { Helmet } from 'react-helmet';
import { PopularPlaylists } from 'layout/components/popular-playlists';
import FormSnippet from 'layout/components/form-snippet/FormSnippet';
import './Default.scss';
import { Artists } from './ArtistsPage';
import useTranslation from 'hooks/useTranslations';

const DefaultPage = () => {
  const translate = useTranslation();
  const helmet = (
    <Helmet>
      <title>{translate('meta.default.title')}</title>
      <meta name='description' content={translate('meta.default.description')} />
    </Helmet>
  );

  return (
    <>
      {helmet}
      <div className='wrapper-music'>
        <h1 className='default-title'>
          {translate('meta.default.h1')}
        </h1>
        <PopularPlaylists title={translate('Popular playlists')} />
      </div>
      <Artists isPreviewArtist={true} />
      <FormSnippet isPreviewTracks={true} staffPicksTags='/render' />
      <PopularPlaylists title={translate('Similar categories')} isSimilarCategories />
    </>
  );
};

export default DefaultPage;
