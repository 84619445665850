import React from 'react';

interface CircleCheckProps {
  isActive: boolean;
  isBlackFriday?: boolean;
  isFree?: boolean;
}

const getFillColor = (isActive: boolean, isBlackFriday?: boolean, isFree?: boolean): string => {
  if (isBlackFriday && isActive && !isFree) {
    return 'var(--bf-accent-color)';
  }
  if (!isBlackFriday && (isActive || isFree)) {
    return 'var(--accent-color)';
  }
  return 'var(--border-color)';
};

export function CircleCheck({ isActive, isBlackFriday, isFree }: CircleCheckProps) {
  const fillColor = getFillColor(isActive, isBlackFriday, isFree);

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM11.7682 15.6402L16.7682 9.64018L15.2318 8.35982L10.9328 13.5186L8.70711 11.2929L7.29289 12.7071L10.2929 15.7071L11.0672 16.4814L11.7682 15.6402Z'
        fill={fillColor}
      />
    </svg>
  );
}
