import { history } from '../redux/store';
import axios from 'axios';
import { AUTH_BY_PARAM } from 'constants/params-names';
import { getTurnstileToken } from 'utils/turnstile';
import { CLOUDFLARE_ERROR_CODE } from 'constants/api';

const redirect = (url) => {
  window.location = url;
};

type AuthProps = {
  purchase?: any;
  redirectUrl?: string;
  turnstileToken?: string;
};

export async function authFacebook({ purchase, redirectUrl, turnstileToken }: AuthProps) {
  const redirect_url = `${window.location.origin}/render?${AUTH_BY_PARAM}=facebook`;
  return await axios
    .post('/v1/UserAuthFacebookUrl', {
      method: 'UserAuthFacebookUrl',
      token: getTurnstileToken(turnstileToken),
      params: {
        redirect_url: redirectUrl
          ? redirectUrl
          : purchase
          ? redirect_url + '&state=' + JSON.stringify(purchase)
          : redirect_url
      }
    })
    .then((response) => {
      if (response.data?.error?.code === CLOUDFLARE_ERROR_CODE) {
        return CLOUDFLARE_ERROR_CODE;
      }
      return (
        response.data &&
        response.data.data &&
        response.data.data.url &&
        redirect(response.data.data.url)
      );
    });
}

export async function authGoogle({ purchase, redirectUrl, turnstileToken }: AuthProps) {
  const redirect_url = `${window.location.origin}/render?${AUTH_BY_PARAM}=google`;
  return await axios
    .post('/v1/UserAuthGoogleUrl', {
      method: 'UserAuthGoogleUrl',
      token: getTurnstileToken(turnstileToken),
      params: {
        redirect_url: redirectUrl
          ? redirectUrl
          : purchase
          ? redirect_url + '&state=' + JSON.stringify(purchase)
          : redirect_url
      }
    })
    .then((response) => {
      if (response.data?.error?.code === CLOUDFLARE_ERROR_CODE) {
        return CLOUDFLARE_ERROR_CODE;
      }

      return (
        response.data &&
        response.data.data &&
        response.data.data.url &&
        redirect(response.data.data.url)
      );
    });
}
