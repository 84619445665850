import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { routes } from './routes';

import './ProfileContent.scss';

export function ProfileContent() {
  return (
    <div className='profile-content'>
      <Switch>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={`/render/profile${route.path}`}
              component={() => {
                return <route.component />;
              }}
            />
          );
        })}
      </Switch>
    </div>
  );
}
