import React from 'react';
import {
  useGetChangePaymentMethodQuery,
  useGetPaymentsQuery,
  useGetInvoiceUpcomingQuery
} from '../../../../redux/api/payments';
import { useSelectPricingInfo } from '../../../../redux/pricing-info/selectors';
import Preloader from 'layout/components/preloader/Preloader';
import useTranslation from 'hooks/useTranslations';
import { NewButton } from 'layout/components/new-button';

import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';
import { NextInvoicePayment } from './NextInvoicePayment';
import { CardImage } from './CardImage';

import './PaymentCards.scss';

const useHasPremiumSubscription = () => {
  const loggedIn = useSelector((state: IAppState) => state.loggedIn);

  return Boolean(
    loggedIn &&
      loggedIn['subs'] &&
      Object.keys(loggedIn['subs']).filter((key) => key !== 'ambassador').length
  );
};

export function PaymentCards() {
  const { data, isLoading: isPaymentsLoading } = useGetPaymentsQuery();
  const { data: changePaymentMethod } = useGetChangePaymentMethodQuery();
  const { data: upcomingInvoice, isLoading: isUpcomingInvoiceLoading } =
    useGetInvoiceUpcomingQuery();
  const { isLoadedForLoggedInUser } = useSelectPricingInfo();
  const hasPremiumSubscription = useHasPremiumSubscription();
  const translate = useTranslation();

  const isLoading = isPaymentsLoading || isUpcomingInvoiceLoading || !isLoadedForLoggedInUser;

  if (isLoading) {
    return (
      <div>
        <Preloader width={100} height={100} />
      </div>
    );
  }

  const changePaymentMethodURL = changePaymentMethod?.data?.url;

  const handleManageSubscription = () => {
    if (hasPremiumSubscription) {
      window.open(changePaymentMethodURL, '_blank', 'noopener,noreferrer');
    } else {
      window.location.href = '/render/pricing';
    }
  };

  return (
    <div className='payment-cards'>
      <div className='payment-cards__info'>
        <div className='payment-cards__header'>{translate('Payment method')}</div>
        <div className='payment-cards__wrapper_mobile'>
          <CardImage data={data} changePaymentMethodURL={changePaymentMethodURL} />
        </div>
        <NextInvoicePayment upcomingInvoice={upcomingInvoice} />
        <NewButton
          onClick={handleManageSubscription}
          className='payment-cards__info_button'
          newgradient
        >
          {hasPremiumSubscription ? translate('Manage subscription') : translate('Go premium')}
        </NewButton>
      </div>
      <div className='payment-cards__wrapper_desktop'>
        <CardImage data={data} changePaymentMethodURL={changePaymentMethodURL} />
      </div>
    </div>
  );
}
