import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { IRoute } from 'interfaces';
import VerticalLayout from 'layout/vertical/Vertical';
import { InternalRouteWrapper, ExternalRouteWrapper } from 'layout/components/route-guards';
import { PageWithBreadcrumbs } from 'layout/vertical/PageWithBreadCrumbs';
import { TurnstilePageWrapper } from 'layout/components/turnstile';
import { usePathPrefix } from 'hooks/useLanguage';
import { routes } from './routes';

const NotFoundPage = loadable(() => import('../sessions/404'));

type RoutesProps = {
  routes: IRoute[];
};

const Routes = ({ routes }: RoutesProps) => {
  const prefix = usePathPrefix();

  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            exact={route.exact}
            path={
              !route.path.startsWith('/') ? `${prefix}/render/${route.path}` : `/${route.path}\``
            }
            component={() => {
              let component = <route.component />;

              if (route.internal) {
                component = (
                  <InternalRouteWrapper>
                    <route.component />
                  </InternalRouteWrapper>
                );
              }

              if (route.external) {
                component = (
                  <ExternalRouteWrapper>
                    <route.component />
                  </ExternalRouteWrapper>
                );
              }

              return (
                <VerticalLayout>
                  <TurnstilePageWrapper>
                    <PageWithBreadcrumbs getBreadcrumbs={route.getBreadcrumbs}>
                      {component}
                    </PageWithBreadcrumbs>{' '}
                  </TurnstilePageWrapper>
                </VerticalLayout>
              );
            }}
          />
        );
      })}

      <Route path='*'>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

const RenderPage = () => <Routes routes={[...routes]} />;

export default RenderPage;
