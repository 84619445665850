import React from 'react';
import { useSelector } from 'react-redux';
import {
  useSelectBestHighestAvailableSubscription,
  useSelectIsPricingInfoLoading
} from '../../../../redux/pricing-info/selectors';
import { ProfileAvatar } from 'layout/components/profile-avatar';
import { IAppState } from 'interfaces';
import classNames from 'utils/class-names';
import useTranslation from 'hooks/useTranslations';

import './ProfileCover.scss';

export function ProfileCover() {
  const subscription = useSelectBestHighestAvailableSubscription();
  const loggedIn = useSelector((state: IAppState) => state.loggedIn);
  const isPricingInfoLoading = useSelectIsPricingInfoLoading();
  const translate = useTranslation();

  const subsiptionClassName = classNames({
    'profile-cover__subscription': true,
    'profile-cover__subscription-loading': isPricingInfoLoading,
    'profile-cover__subscription-none': subscription?.name === undefined,
    'profile-cover__subscription-ambassador': subscription?.name === 'Ambassador'
  });

  return (
    <div className='profile-cover'>
      <div className='profile-cover__wrapper'>
        <ProfileAvatar className='profile-cover__avatar' />
        <div className='profile-cover__user'>
          {loggedIn['firstname'] && (
            <p className='profile-cover__user-name'>{loggedIn['firstname']}</p>
          )}
          {loggedIn['email'] && <p className='profile-cover__user-email'>{loggedIn['email']}</p>}
        </div>
      </div>
      <p className={subsiptionClassName}>
        {subscription?.name ? subscription.name : `${translate('No subscriptions')}`}
      </p>
    </div>
  );
}
