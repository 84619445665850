import React from 'react';
import { ArtistCard } from './artist-card';
import './artists.scss';
import { useGetPageInfoByNameQuery } from '../../../redux/api/page-info';
import Preloader from 'layout/components/preloader/Preloader';
import { LinkButton } from 'layout/components/link-button';
import { useMediaQuery } from 'react-responsive';
import { BettaIcon } from 'layout/components/icons/betta-icon';
import useTranslation from 'hooks/useTranslations';
import { useLanguage } from 'hooks';
import { usePush } from 'hooks/usePush';

type ArtistProps = {
  isPreviewArtist?: boolean;
};
export function Artists({ isPreviewArtist }: ArtistProps) {
  const language = useLanguage();
  const { data: artists, isLoading } = useGetPageInfoByNameQuery({
    routing: 'artists',
    lang: language
  });
  const translate = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  let displayedArtists = artists?.sub || [];

  const ArtistList = ({ displayedArtists, isPreviewArtist }) => {
    return (
      <>
        {displayedArtists.map(({ artist, title, routing }) => (
          <ArtistCard
            img={artist.image?.large}
            confirmed={true}
            name={artist.fullname}
            link={routing}
            nickname={artist.nickname}
            tracksCount={artist.tracks_count}
            key={title}
            isPreviewArtist={isPreviewArtist}
          />
        ))}
      </>
    );
  };

  const artistToDisplay =
    isMobile && isPreviewArtist ? displayedArtists.slice(0, 6) : displayedArtists;
  const push = usePush();

  return (
    <div className='artist'>
      <div className='wrapper-music'>
        <h1 className={isPreviewArtist ? 'explore-title' : ''}>
          {isPreviewArtist ? translate('Explore Artists') : translate('meta.atrists.h1')}{' '}
          {isPreviewArtist && <BettaIcon className='explore-title__beta' />}
        </h1>
      </div>
      {isLoading ? (
        <Preloader width={100} height={100} />
      ) : (
        <div className={isPreviewArtist ? 'preview-artist-list artist__list' : 'artist__list'}>
          <ArtistList displayedArtists={artistToDisplay} isPreviewArtist={isPreviewArtist} />
        </div>
      )}
      {isPreviewArtist && (
        <LinkButton
          className='preview-artist-list__link'
          onClick={() => push('/render/artists')}
          type='secondary'
        >
          {translate('All artists')}
        </LinkButton>
      )}
    </div>
  );
}
