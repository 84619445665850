import React, { useState } from 'react';
import { TimeLimitIcon } from 'layout/components/icons/time-limit-icon';
import BellIcon from 'assets/icons/bell-profile.svg';
import { ToggleButton } from 'ui/toggle-button';
import { ProfileLimits } from './ProfileLimits';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'interfaces';
import { Button } from 'layout/components/app-button';
import { useUpdateUserProfileMutation } from '../../../../redux/api/profile-page';

import className from 'utils/class-names';
import useTranslation from 'hooks/useTranslations';
import { addNotification } from '../../../../redux/notifications/actions';

import './ProfileDetails.scss';
import { usePush } from 'hooks/usePush';

type ProfilDetailsHeaderProps = {
  icon: React.ReactNode;
  title: string;
  children?: React.ReactNode;
};

function ProfileDetailsHeader({ icon, title, children }: ProfilDetailsHeaderProps) {
  return (
    <div className='profile-details__header'>
      <div className='profile-details__header-icon'>{icon}</div>
      <div>{title}</div>
      {children}
    </div>
  );
}

export function ProfileDetails({ emailNotification }: { emailNotification: boolean }) {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state: IAppState) => state.loggedIn);
  const [isChecked, setIsChecked] = useState(emailNotification);
  const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();
  const push = usePush();
  const isWithSubscriptions = Boolean(
    loggedIn &&
      loggedIn['subs'] &&
      Object.keys(loggedIn['subs']).filter((key) => key !== 'ambassador').length
  );
  const isAmbassador = !isWithSubscriptions && loggedIn;
  const translate = useTranslation();

  const handleToggleClick = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    updateUserProfile(newChecked).then(() => {
      dispatch(
        addNotification({
          title: newChecked
            ? 'Newsletter successfully activated'
            : 'Newsletter successfully deactivated'
        })
      );
    });
  };

  const toggleWrapperClassName = className({
    'profile-details__toggle': true,
    'profile-details__toggle_loading': isLoading
  });

  return (
    <div className='profile-details'>
      <div className='profile-details__item'>
        {isAmbassador ? (
          <div>
            <div className='profile-details__title'>{translate("Сhoose your subscription")}</div>
            <div className='profile-details__text'>
              {translate("Discover all of the features of Mubert with a subscription plan that suits your needs.")}
            </div>
            <Button
              className='profile-details__button'
              onClick={() => push('/render/pricing')}
            >
              {translate("Go premium")}
            </Button>
          </div>
        ) : (
          <>
            <ProfileDetailsHeader icon={<TimeLimitIcon />} title={translate('Limits')} />
            <div className='profile-details__settings'>
              <ProfileLimits />
            </div>
          </>
        )}
      </div>
      <div className='profile-details__item'>
        <ProfileDetailsHeader icon={<img src={BellIcon} alt='bell-icon' />} title={translate("Newsletter")}>
          <div className={toggleWrapperClassName}>
            <ToggleButton
              isChecked={isChecked}
              onChange={handleToggleClick}
              size='M'
              id='newsletterToggle'
            />
          </div>
        </ProfileDetailsHeader>
      </div>
    </div>
  );
}
