import React from 'react';

export function GoogleIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' fill='none' >
      <rect width='50' height='50' rx='25' fill='var(--icons-background)' />
      <path
        d='M38.3189 21.9011H37.1944V21.8432H24.6311V27.4269H32.5202C31.3692 30.6773 28.2766 33.0106 24.6311 33.0106C20.0057 33.0106 16.2556 29.2604 16.2556 24.635C16.2556 20.0096 20.0057 16.2595 24.6311 16.2595C26.7662 16.2595 28.7086 17.0649 30.1876 18.3806L34.136 14.4322C31.6428 12.1087 28.308 10.6758 24.6311 10.6758C16.9221 10.6758 10.6719 16.926 10.6719 24.635C10.6719 32.344 16.9221 38.5943 24.6311 38.5943C32.3401 38.5943 38.5904 32.344 38.5904 24.635C38.5904 23.6991 38.494 22.7854 38.3189 21.9011Z'
        fill='#FFC107'
      />
      <path
        d='M12.2812 18.1377L16.8676 21.5012C18.1085 18.4287 21.114 16.2595 24.631 16.2595C26.7661 16.2595 28.7085 17.0649 30.1875 18.3806L34.1358 14.4322C31.6427 12.1087 28.3079 10.6758 24.631 10.6758C19.2692 10.6758 14.6194 13.7028 12.2812 18.1377Z'
        fill='#FF3D00'
      />
      <path
        d='M24.6303 38.5945C28.236 38.5945 31.5123 37.2147 33.9893 34.9707L29.6689 31.3148C28.2203 32.4165 26.4502 33.0123 24.6303 33.0109C20.9995 33.0109 17.9166 30.6957 16.7552 27.4648L12.2031 30.9721C14.5134 35.4928 19.2051 38.5945 24.6303 38.5945Z'
        fill='#4CAF50'
      />
      <path
        d='M38.3284 21.9017H37.2039V21.8438H24.6406V27.4274H32.5297C31.9791 28.9744 30.9874 30.3262 29.6771 31.3158L29.6792 31.3144L33.9996 34.9703C33.6939 35.2481 38.5999 31.6152 38.5999 24.6356C38.5999 23.6996 38.5035 22.786 38.3284 21.9017Z'
        fill='#1976D2'
      />
    </svg>
  );
}
