import { IRoute } from 'interfaces';
import { ProfileAccount } from './ProfileAccount';
import { ConnectChannel } from './ConnectChannel';
import { Subscription } from './Subscription';

export const routes: IRoute[] = [
  {
    path: '/',
    component: ProfileAccount,
    exact: true
  },
  {
    path: '/connect-channel',
    component: ConnectChannel
  },
  {
    path: '/subscription',
    component: Subscription
  }
];

export const PROFILE_ROUTES = routes.map((route) => route.path);
