import React, { useEffect, useRef, useState } from 'react';
import Slider from 'rc-slider';

import WaveSurfer from 'wavesurfer.js';
import { fmtMSS } from '../../../utils/formatters';
import { generateWaveForm } from '../../../utils/waveform-utils';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useIsomorphicLayoutEffect } from 'hooks/useIsomorphicLayoutEffect';
import { IAppState } from '../../../interfaces/app-state';
import './Waveform.scss';
import { setCurrentTrack, setPlaying, setTimePlaying } from '../../../redux/current-track/actions';

import PlayerButton from '../player-button/PlayerButton';
import { NavLink } from 'layout/components/nav-link';
import StartAudioContext from 'startaudiocontext/StartAudioContext';
import { DownloadIcon } from 'layout/components/icons/download-icon';
import { useSelectBestHighestAvailableSubscription } from '../../../redux/pricing-info/selectors';
import { setPurchase, stripeOrder } from '../../../redux/purchase/actions';
import { showModal, showPurchaseModal } from '../../../redux/modals/actions';
import { ModalNames } from 'constants/modal-names';
import { useAppSelector } from 'hooks/store';
import { getColorBySessionId } from 'utils/track';
import { addNotification } from '../../../redux/notifications/actions';
import { IconBase } from '../tracklist/IconBase';
import { VolumeIconMedium, VolumeIconHigh, VolumeIconOff } from '../icons/volume-icons';
import { ExitIcon } from '../icons/exit-icon';
import { Wave } from './Wave';

const formWaveSurferOptions = (ref, isDarkTheme) => ({
  container: ref,
  waveColor: isDarkTheme ? '#ACB4BC' : '#EDEEEE',
  progressColor: '#000DFF',
  cursorColor: 'transparent',
  backend: 'MediaElement',
  barWidth: 2,
  barGap: 2,
  barRadius: 2,
  responsive: false,
  normalize: true,
  height: 50,
  maxCanvasWidth: 600
});

export default function Waveform({ session_id = undefined }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const songRef = useRef(null);
  const sliderContainer = useRef(null);
  const hoverRef = useRef<any>();

  const [url, setUrl] = useState('');
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [waveform, setWaveform] = useState([]);
  const [volume, setVolume] = useState(0.5);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [track, setTrack] = useState(undefined);
  const [showVolume, setShowVolume] = useState(false);
  const isLoggedIn = useAppSelector((state) => state.loggedIn);
  const availableSubscription = useSelectBestHighestAvailableSubscription();
  const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme);
  const waveformTitleRef = useRef(null);
  const trackNameRef = useRef(null);
  const [isTitleLonger, setIsTitleLonger] = useState(false);

  const playing = useSelector<IAppState, boolean>((state) => state.playing);

  const playerMeta = useSelector<IAppState, object>((state) => state.playerMeta);

  const dispatch = useDispatch();

  const handleClickOutside = (event) => {
    if (sliderContainer.current && !sliderContainer.current.contains(event.target)) {
      setShowVolume(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const processPlayerMeta = () => {
    if (playerMeta && playerMeta['session_id']) {
      if (playerMeta['session_id'] === session_id) {
        if (
          wavesurfer &&
          wavesurfer.current &&
          wavesurfer.current.backend &&
          wavesurfer.current.getDuration()
        ) {
          wavesurfer.current.seekTo(playerMeta['progress']);
        }
      }
    }
  };
  const getVolumeIcon = () => {
    if (volume > 0.66) {
      return <VolumeIconHigh />;
    } else if (volume > 0.01 && volume <= 0.66) {
      return <VolumeIconMedium />;
    }
    return <VolumeIconOff />;
  };

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    if (wavesurfer && wavesurfer.current) {
      wavesurfer.current.destroy();
    }
  }, [session_id]);

  useIsomorphicLayoutEffect(() => {
    if (!session_id) {
      return;
    }

    axios
      .get(`/v1?method=TrackInfo&session_id=${session_id}&url_type=all`)
      .then((response) => {
        const data = response.data.data;
        const error = response.data.error;

        if (error) {
          const text = error.text || 'Unknown error during playing track';

          dispatch(
            addNotification({
              title: text
            })
          );
          dispatch(setCurrentTrack(null));
        } else if (data) {
          const track = data.track;
          const url = track.track;

          if (!url) {
            return;
          }
          setUrl(url);
          setImage(track.image_url);
          setWaveform(generateWaveForm(track.waveform));
          setTitle(track.name);
          setTrack(track);
          setDuration(track.duration);
        }
      })
      .catch(() => {
        dispatch(
          addNotification({
            title: 'Unknown error during playing track'
          })
        );
        dispatch(setCurrentTrack(null));
      });
  }, [session_id]);

  useEffect(() => {
    return () => {
      if (wavesurfer && wavesurfer.current) {
        // Removes events, elements and disconnects Web Audio nodes.
        // when component unmount
        wavesurfer.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    processPlayerMeta();
  }, [playerMeta]);

  useIsomorphicLayoutEffect(() => {
    if (wavesurfer && wavesurfer.current && wavesurfer.current.backend) {
      if (playing) {
        wavesurfer.current.play();
      } else {
        wavesurfer.current.pause();
      }
    }
  }, [playing]);

  useIsomorphicLayoutEffect(() => {
    if (waveform.length) {
      const options = formWaveSurferOptions(waveformRef.current, isDarkTheme);
      if (wavesurfer && wavesurfer.current) {
        // Removes events, elements and disconnects Web Audio nodes.
        // when component unmount
        wavesurfer.current.destroy();
      }
      wavesurfer.current = WaveSurfer.create(options);

      const handlePointerMove = (e) => {
        if (hoverRef.current) {
          hoverRef.current.style.width = `${e.offsetX}px`;
        }
      };
      wavesurfer.current.container.addEventListener('pointermove', handlePointerMove);

      wavesurfer.current.load(songRef.current, waveform);
      wavesurfer.current.on('ready', function () {
        // https://wavesurfer-js.org/docs/methods.html
        // make sure object stillavailable when file loaded
        if (wavesurfer.current) {
          wavesurfer.current.setVolume(volume);
          setVolume(volume);
          dispatch(setPlaying(true));
          wavesurfer.current.play();
          processPlayerMeta();
        }
      });

      wavesurfer.current.on('finish', function () {
        dispatch(setPlaying(false));
        wavesurfer.current.pause();
      });

      wavesurfer.current.drawer.on('click', (e, progress) => {
        setTimeout(() => wavesurfer.current.seekTo(progress), 0);
      });

      wavesurfer.current.on('audioprocess', function () {
        if (wavesurfer.current.backend && wavesurfer.current.isPlaying()) {
          const currentTime = parseInt(wavesurfer.current.getCurrentTime());
          if (time !== currentTime || currentTime === 0) {
            setTime(currentTime);
            dispatch(setTimePlaying(wavesurfer.current.getCurrentTime()));
          }
        }
      });
      StartAudioContext(wavesurfer.current.backend.getAudioContext(), '.audiocontext');
    }
  }, [waveform]);

  const downloadTrack = () => {
    if (!isLoggedIn) {
      dispatch(
        showModal({
          name: ModalNames.DownloadTrack,
          small: true
        })
      );

      return;
    }

    if (track.flag_artist) {
      dispatch(
        showModal({
          name: ModalNames.PurchaseArtist,
          additionalProps: {
            sessionId: track.session_id,
            trackName: track.name,
            purchases: track.purchases ? Object.keys(track.purchases) : [],
            artistUrl: track.artist.routing,
            artistName: track.artist.fullname,
            artistImage: track.artist.image?.medium
          }
        })
      );

      return;
    }

    if (availableSubscription && track) {
      dispatch(
        stripeOrder({
          sessionId: track.session_id,
          priceId: availableSubscription.priceId
        })
      );
    } else if (track) {
      dispatch(showPurchaseModal());
      dispatch(setPurchase(track));
    }
  };

  const handlePlayPause = () => {
    dispatch(setPlaying(!playing));
  };

  const onVolumeChange = (newVolume) => {
    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  useEffect(() => {
    if (waveformTitleRef.current && trackNameRef.current) {
      const titleWidth = waveformTitleRef.current.offsetWidth;
      const trackNameWidth = trackNameRef.current.offsetWidth;
      setIsTitleLonger(trackNameWidth > titleWidth);
    }
  }, [session_id, title, waveformTitleRef, trackNameRef]);

  return url ? (
    <div className='waveform-player' key={session_id}>
      <PlayerButton
        className={'player-btn audiocontext'}
        url={image}
        playing={playing}
        focused={true}
        size={50}
        onClick={handlePlayPause}
        name={track?.name}
        useWhiteButtons={true}
        color={track && !track.flag_staffpicks ? getColorBySessionId(track.session_id) : undefined}
      />
      <div className='waveform-player__title' ref={waveformTitleRef}>
        <div className='waveform-player__track'>
          <div className={isTitleLonger ? 'track-name move' : 'track-name'}>
            <NavLink to={`/render/tracks/${session_id}`} ref={trackNameRef}>
              {title}
            </NavLink>
          </div>

          {isTitleLonger && (
            <div className={isTitleLonger ? 'track-name move' : 'track-name'}>
              <NavLink to={`/render/tracks/${session_id}`} ref={trackNameRef}>
                {' '}
                {title}
              </NavLink>
            </div>
          )}
        </div>
        <div className='waveform-player__time'>{fmtMSS(time)}</div>
      </div>
      <Wave
        time={time}
        duration={duration}
        url={url}
        songRef={songRef}
        waveformRef={waveformRef}
        hoverRef={hoverRef}
      />
      <div className='waveform-player__controls'>
        <IconBase
          className='waveform-player__controls-icon'
          onClick={() => setShowVolume(!showVolume)}
        >
          {getVolumeIcon()}
        </IconBase>
        <IconBase onClick={() => downloadTrack()}>
          <DownloadIcon />
        </IconBase>
        <IconBase
          className='waveform-player__controls-icon'
          onClick={() => {
            dispatch(setTimePlaying(0));
            dispatch(setCurrentTrack(null));
            dispatch(setPlaying(false));
          }}
        >
          <ExitIcon />
        </IconBase>
        {showVolume && (
          <div className='waveform-player__volume' ref={sliderContainer}>
            <div className='waveform-player__volume-slider' style={{ width: 130 }}>
              <Slider
                onChange={(nextValues) => {
                  onVolumeChange(nextValues);
                }}
                min={0.0001}
                max={0.9751}
                step={0.025}
                defaultValue={volume}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div ref={waveformRef} />
  );
}
