import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import useTranslation from 'hooks/useTranslations';
import { Payments } from './Payments';
import { PaymentCards } from './PaymentCards';
import { CurrentSubscription } from './CurrentSubscription';
import { Tabs } from 'layout/components/tabs';

import './Subscription.scss';
import {
  useSelectBestHighestAvailableSubscription,
  useSelectPricingInfo
} from '../../../../redux/pricing-info/selectors';

const { TabPane } = Tabs;

export function Subscription() {
  const [activeTab, setActiveTab] = useState<'pricing' | 'payments'>('pricing');
  const translate = useTranslation();
  const subscription = useSelectBestHighestAvailableSubscription();
  const { isLoadedForLoggedInUser } = useSelectPricingInfo();

  if (!subscription && isLoadedForLoggedInUser) {
    return <Redirect to={'/render/profile'} />;
  }

  const handleTabChange = (prop) => {
    setActiveTab(prop);
  };

  return (
    <div className='subscription'>
      <Tabs
        size='small'
        onChange={handleTabChange}
        activeKey={activeTab}
        className='subscription__tabs'
      >
        <TabPane tab={translate('Payment history')} key='pricing'>
          <Payments />
          <CurrentSubscription />
        </TabPane>
        <TabPane tab={translate('Payment methods')} key='payments'>
          <PaymentCards />
          <CurrentSubscription />
        </TabPane>
      </Tabs>
    </div>
  );
}
