import { PaymentMethod } from '@stripe/stripe-js';
import { usePaymentIcon } from 'hooks';
import { Carousel } from 'layout/components/carousel';
import { ArrowRight } from 'layout/components/icons/arrow-right';
import { NewButton } from 'layout/components/new-button';
import React, { useState } from 'react';
import classNames from 'utils/class-names';
import useTranslation from 'hooks/useTranslations';
import './CardImage.scss';

type CardImageProps = {
  data: PaymentMethod[];
  changePaymentMethodURL: string;
};

export const CardImage = ({ data, changePaymentMethodURL }: CardImageProps) => {
  const translate = useTranslation();
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const cardsCount = data.length || 0;

  const showNextCard = () => {
    setCurrentCardIndex((prevIndex) => prevIndex + 1);
  };

  const showPrevCard = () => {
    setCurrentCardIndex((prevIndex) => prevIndex - 1);
  };

  const leftArrowClassName = classNames({
    'card-image__arrow': true,
    'card-image__arrow_left': true,
    'card-image__arrow_disabled': currentCardIndex <= 0 || cardsCount === 1
  });

  const rightArrowClassName = classNames({
    'card-image__arrow': true,
    'card-image__arrow_right': true,
    'card-image__arrow_disabled': currentCardIndex >= cardsCount - 1 || cardsCount === 1
  });

  return (
    <div className='card-image'>
      {!cardsCount ? (
        <>
          <div
            className='card-image__no-cards'
            dangerouslySetInnerHTML={{ __html: translate('No payment methods') }}
          />
        </>
      ) : (
        <>
          <div className='card-image__title'>{translate('Linked payment methods')}</div>
          <Carousel
            className='card-image__carousel'
            slides={data.map((paymentMethod) => {
              return <CardIcon card={paymentMethod.card} />;
            })}
            currentImageIndex={currentCardIndex}
            onIndexChange={setCurrentCardIndex}
          />
          <div className='card-image__buttons'>
            <NewButton
              onClick={() => {
                window.open(changePaymentMethodURL, '_blank', 'noopener,noreferrer');
              }}
              className='card-image__button'
              size='s'
            >
              {translate('Change payment method')}
            </NewButton>
            <div className={leftArrowClassName} onClick={showPrevCard}>
              <ArrowRight />
            </div>
            <div className={rightArrowClassName} onClick={showNextCard}>
              <ArrowRight />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const CardIcon = ({ card }: { card: PaymentMethod['card'] }) => {
  const hasMobilePay = Boolean(
    card?.wallet?.type === 'google_pay' || card?.wallet?.type === 'apple_pay'
  );
  const { icon: PaymentIcon, text: cardText } = usePaymentIcon(card?.brand);
  const { icon: WalletIcon, text: walletText } = usePaymentIcon(card?.wallet?.type);
  const cardImageClassName = classNames({
    'card-image__card': true,
    'card-image__card_link': card?.brand === 'link'
  });

  return (
    <div className={cardImageClassName}>
      <img src={PaymentIcon} alt='Payment icon' className='card-image__card_icon' />
      {hasMobilePay && (
        <div className='card-image__card_wallet'>
          <img src={WalletIcon} alt='Wallet icon' />
          <div className='card-image__card_wallet_text'>{walletText}</div>
        </div>
      )}
      <div className='card-image__card_title'>{hasMobilePay ? 'Bank card' : cardText}</div>
      <div className='card-image__card_number'>{card?.last4}</div>
    </div>
  );
};
