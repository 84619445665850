import React from 'react';

import { ProfileTabs } from './ProfileTabs';
import { ProfileContent } from './ProfileContent';

import './ProfilePage.scss';

export function ProfilePage() {
  return (
    <div className='profile'>
      <div className='profile__settings'>
        <ProfileTabs />
      </div>
      <div className='profile__info'>
        <ProfileContent />
      </div>
    </div>
  );
}
