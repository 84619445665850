import React from 'react';
import { useLocation } from 'hooks/useLocation';
import { ProfileIcon } from 'layout/components/icons/profile-icon';
import { PinIcon, CartIcon, BinIcon } from 'layout/components/icons';
import classNames from 'utils/class-names';
import { ProfileTabDropdown } from './ProfileTabDropdown';
import { useShowDeleteProfileModal } from './useShowDeleteProfileModal';
import useTranslation from 'hooks/useTranslations';
import { useSelectBestHighestAvailableSubscription } from '../../../../redux/pricing-info/selectors';
import { usePush } from 'hooks/usePush';

import './ProfileTabs.scss';

type ProfileTabProps = {
  children: React.ReactNode;
  isActive?: boolean;
  isDelete?: boolean;
  isSelector?: boolean;
  isDropdown?: boolean;
  onClick?: () => void;
  icon: React.ComponentType<any>;
  disabled?: boolean;
};

export function ProfileTab({
  children,
  disabled,
  isActive,
  isDelete,
  isSelector,
  isDropdown,
  onClick,
  icon: IconComponent
}: ProfileTabProps) {
  const className = classNames({
    'profile-tab': true,
    'profile-tab--active': isActive,
    'profile-tab--delete': isDelete,
    'profile-tab--selector': isSelector,
    'profile-tab--dropdown': isDropdown,
    'profile-tab--disabled': disabled
  });

  return (
    <div className={className} onClick={onClick}>
      <IconComponent />
      <div className='profile-tab__content'>{children}</div>
    </div>
  );
}

export function ProfileTabs() {
  const push = usePush();
  const { pathname } = useLocation();
  const showDeleteProfileModal = useShowDeleteProfileModal();
  const translate = useTranslation();
  const subscription = useSelectBestHighestAvailableSubscription();

  return (
    <div className='profile-tabs'>
      <div className='profile-tabs__title'>{translate('Profile')}</div>
      <div className='profile-tabs__wrapper'>
        <ProfileTab
          isActive={pathname.endsWith('/render/profile')}
          icon={ProfileIcon}
          onClick={() => {
            if (!pathname.endsWith('/render/profile')) {
              push('/render/profile');
            }
          }}
        >
          {translate('Account')}
        </ProfileTab>
        <ProfileTab
          disabled={!subscription}
          isActive={pathname.endsWith('/render/profile/subscription')}
          icon={CartIcon}
          onClick={() => {
            if (!pathname.endsWith('/render/profile/subscription')) {
              push('/render/profile/subscription');
            }
          }}
        >
          {translate('Subscription')}
        </ProfileTab>
        <ProfileTab
          isActive={pathname.endsWith('/render/profile/connect-channel')}
          icon={PinIcon}
          onClick={() => {
            if (!pathname.endsWith('/render/profile/connect-channel')) {
              push('/render/profile/connect-channel');
            }
          }}
        >
          {translate('Linked Channels')}
        </ProfileTab>
        <ProfileTab isDelete onClick={showDeleteProfileModal} icon={BinIcon}>
          {translate('Delete account')}
        </ProfileTab>
      </div>
      <ProfileTabDropdown />
    </div>
  );
}
