import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/store';
import { Modal } from 'layout/components/modal';
import { hideModal } from '../../../redux/modals/actions';
import classNames from 'utils/class-names';
import { ModalNames } from 'constants/modal-names';

type ModalsProps = {
  modalNamesMap: Partial<Record<ModalNames, React.ComponentType<any>>>;
};

export function Modals({ modalNamesMap }: ModalsProps) {
  const {
    isVisible: isModalVisible,
    name: modalName,
    onComplete,
    onClose,
    width,
    additionalProps,
    small,
    extraSmall,
    large,
    big,
    fullHeight,
    showMask,
    closable,
    maskClosable
  } = useAppSelector((state) => state.modals);
  const classes = classNames({
    'app-modal_small': !!small,
    'app-modal_extra-small': !!extraSmall,
    'app-modal_large': !!large,
    'app-modal_big': !!big,
    'app-modal_full-height': !!fullHeight
  });

  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(hideModal());

    if (onClose) {
      onClose();
    }
  };

  const Component = modalNamesMap[modalName];

  return (
    <Modal
      closable={closable}
      maskClosable={maskClosable}
      showMask={showMask}
      width={width ? width : 1180}
      centered
      visible={isModalVisible}
      onCancel={handleCancel}
      className={classes}
    >
      {Component && <Component onSubmit={onComplete} onClose={handleCancel} {...additionalProps} />}
    </Modal>
  );
}
