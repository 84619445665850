import { Carousel } from 'layout/components/carousel';
import React from 'react';
import './whats-new-modal.scss';
import { useAppSelector } from 'hooks/store';
import { unixToDdMonthYyyy } from 'utils/unixTimestampTransform';
import { New } from '../../../../../redux/api/whatsnew';
import { useLanguage } from 'hooks/useLanguage';

type WhatsNewModalProps = {
  news: New;
};

export function WhatsNewModal({ news }: WhatsNewModalProps) {
  const isMobile = useAppSelector((state) => state.settings.isMobile);
  const images = isMobile ? news.mobile_images : news.images;
  const language = useLanguage();

  const description  = news.main_description[language].replace(/\\n/g, '\n');
  return (
    <div className='whats-new-modal'>
      <div className='whats-new-modal__content'>
        <div className='whats-new-modal__date'>{unixToDdMonthYyyy(news.date)}</div>
        <div className='whats-new-modal__title'>{news.title[language]}</div>
        <div className='whats-new-modal__text'>{description}</div>
      </div>
      <div className='whats-new-modal__image-container'>
        <Carousel slides={images} />
      </div>
    </div>
  );
}
