import { useAppSelector } from 'hooks/store';
import React from 'react';

export function SelectArrowDown() {
    const isDarkTheme = useAppSelector(state => state.settings.isDarkTheme);
  return (
    <svg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.18077 6.32967L0.291993 2.20285C-0.357529 1.27496 0.306286 -8.85647e-07 1.43892 -8.36138e-07L6.56108 -6.12241e-07C7.69371 -5.62732e-07 8.35753 1.27496 7.70801 2.20285L4.81923 6.32967C4.42113 6.89839 3.57887 6.89838 3.18077 6.32967Z'
        fill={isDarkTheme ? 'white' : '#363C43'}
      />
    </svg>
  );
}
