import React from 'react';
import { ArrowRight } from 'layout/components/icons/arrow-right';
import classNames from 'utils/class-names';

import './AuthWithSocials.scss';

type SocialLinkProps = {
  icon: React.ReactNode;
  id: string | null;
  email: string;
  platform: string;
  onClick: () => void;
};

export function AuthWithSocials({ icon, id, email, platform, onClick }: SocialLinkProps) {
    const className = classNames ({
      'social-link': true,
      'social-link--active': !id
    })

    const hasGoogleVerification =  id && platform === 'google' ? true : false;
    const hasFacebookVerification =  id && platform === 'facebook' ? true : false;

  return (
    <div
      className={className}
      onClick={!id ? onClick : undefined}
    >
      {icon}
      {hasGoogleVerification ? email : ''}
      {platform === 'facebook' && (
        <div className='social-link__placeholder'>{`FB ID:`}</div>
      )}
      {hasFacebookVerification ?  id : ''}
      {!id ? <ArrowRight className='social-link__arrow' /> : ''}

    </div>
  );
}
