import React from 'react';

export function FacebookIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' fill='none'>
      <rect width='50' height='50' rx='25' fill='var(--icons-background)' />
      <g clipPath='url(#clip0_13351_4585)'>
        <path
          d='M39.4201 24.6358C39.4201 16.4729 32.8027 9.85547 24.6397 9.85547C16.4768 9.85547 9.85938 16.4729 9.85938 24.6358C9.85938 32.0131 15.2644 38.1279 22.3303 39.2367V28.9083H18.5775V24.6358H22.3303V21.3795C22.3303 17.6752 24.537 15.6291 27.9131 15.6291C29.5302 15.6291 31.2216 15.9177 31.2216 15.9177V19.5551H29.3579C27.5218 19.5551 26.9492 20.6944 26.9492 21.8634V24.6358H31.0484L30.3931 28.9083H26.9492V39.2367C34.0151 38.1279 39.4201 32.0132 39.4201 24.6358Z'
          fill='#1877F2'
        />
        <path
          d='M30.3938 28.9081L31.0491 24.6357H26.9498V21.8632C26.9498 20.6942 27.5224 19.5549 29.3586 19.5549H31.2223V15.9176C31.2223 15.9176 29.5308 15.6289 27.9137 15.6289C24.5376 15.6289 22.331 17.6751 22.331 21.3794V24.6357H18.5781V28.9081H22.331V39.2365C23.0949 39.3562 23.8671 39.4163 24.6404 39.4161C25.4137 39.4163 26.1858 39.3563 26.9498 39.2365V28.9081H30.3938Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_13351_4585'>
          <rect
            width='29.5607'
            height='29.5607'
            fill='white'
            transform='translate(9.85938 9.85547)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
