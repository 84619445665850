import React, { useMemo } from 'react';
import { GradientText } from 'layout/components/gradient-text';

import {
  useSelectAvailableSubscriptions,
  useSelectBestHighestAvailableSubscription
} from '../../../../redux/pricing-info/selectors';
import useTranslation from 'hooks/useTranslations';

import './NextInvoicePayment.scss';
import { unixToDdMmYyyy } from 'utils/unixTimestampTransform';
import { InvoiceData } from 'interfaces';

type NexInvoiceProps = {
  upcomingInvoice?: InvoiceData;
};

const useNextInvoiceInfo = (upcomingInvoice?: InvoiceData) => {
  const translate = useTranslation();

  return useMemo(() => {
    const nextPaymentDate = unixToDdMmYyyy(upcomingInvoice?.created);

    const nextInvoiceText = upcomingInvoice?.created ? `${translate('on')} ${nextPaymentDate}` : '';

    const nextInvoicePrice =
      !!upcomingInvoice?.total || upcomingInvoice?.total === 0
        ? `$${upcomingInvoice.total / 100}`
        : null;

    return {
      nextPaymentDate,
      nextInvoiceText,
      nextInvoicePrice
    };
  }, [upcomingInvoice, translate]);
};

const useInvoiceSubscription = (upcomingInvoice: InvoiceData | undefined) => {
  const subscriptions = useSelectAvailableSubscriptions();
  const bestAvailableSubscription = useSelectBestHighestAvailableSubscription();
  const targetSubscriptionId = upcomingInvoice?.lines?.[0]?.price?.id;

  const targetSubscription =
    !!targetSubscriptionId && subscriptions.find((item) => item.priceId === targetSubscriptionId);

  return targetSubscription || bestAvailableSubscription;
};

export const NextInvoicePayment = ({ upcomingInvoice }: NexInvoiceProps) => {
  const translate = useTranslation();
  const { nextInvoiceText, nextInvoicePrice } = useNextInvoiceInfo(upcomingInvoice);
  const subscription = useInvoiceSubscription(upcomingInvoice);

  const subscriptionStatus = subscription.isCanceled ? 'canceled' : 'active';

  return (
    <div className='next-invoice'>
      <div className='next-invoice__title'>{translate('Subscription')}</div>
      <div className='next-invoice__info'>
        {subscription && !subscription.isFree ? (
          <>
            <GradientText weight='bold' className='capitalize'>
              {subscription?.name} {subscription.period}
            </GradientText>
            <span> {translate(subscriptionStatus)}</span>
          </>
        ) : (
          <GradientText weight='bold'>{translate('No subscription')}</GradientText>
        )}
      </div>
      <div className='next-invoice__title'>{translate('Next payment')}</div>
      {nextInvoiceText || nextInvoicePrice ? (
        <div className='next-invoice__price'>
          {nextInvoicePrice}
          <div className='next-invoice__text'>{nextInvoiceText}</div>
        </div>
      ) : (
        <div className='next-invoice__text'>{translate('No future payment')}</div>
      )}
    </div>
  );
};
